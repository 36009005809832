/* eslint-disable class-methods-use-this */
import { action, observable, computed, runInAction, autorun } from 'mobx';
import { fromPromise } from 'mobx-utils';
import _ from 'lodash';
import { setToken, removeToken } from 'api';
// import LogRocket from 'logrocket';
import { dehydrateError } from 'utils/error';
import { setUserInfo } from 'utils/errorReport';
import { authenticate, authenticateWithAssistanceCode, authenticateWithGoogle, verify, ping } from 'api/account';
import { create as createTeacher } from 'api/teacher';
import { create as createStudent } from 'api/student';
import { getNS } from '_i18n_';
import accountStateStoreHook from 'store/statehook/accountStateStoreHook';
import classRoomStore from './classRoomStore';
// import userMediaStore from './userMediaStore';
import webStorageStore from './webStorageStore';

const _t = getNS('store/userStore.js');

const log = require('debug')('ui:userStore');

export const STORAGE_PREFIX = 'USER_STORE__';

const ENVIRONMENT_ID_KEY = `${STORAGE_PREFIX}ENV_ID`;

const USERS_MANAGEMENT_KEY = `${STORAGE_PREFIX}USER_MANAGEMENT`;

const UMID_IN_SESSION_KEY = `${STORAGE_PREFIX}SESSION_UMID`;

// eslint-disable-next-line
/**
 * Define user store class to handle user stuffs.
 *
 * @todo {@link loadProfileFromLocalStorage} is called twice (App.jx and here).
 */
class UserStore {
  constructor() {
    if (!this.environmentId) {
      this.generateEnvironmentId();
    }

    this.loadProfileFromLocalStorage();
    autorun(() => {
      log('current user has changed', this.currentUser);
      if (this.currentUser) {
        this.generateEnvironmentId();
      } else {
        this.cleanEnvironmentId();
      }
    }, { name: 'UserStore constructor autorun' });
    this.ping();
  }

  @observable all = [];
  @observable isVerifying = false;
  @observable isUserVerified = false;
  @observable currentUser = undefined;
  @observable currentUserSecurityData = undefined;
  @observable currentUserAccessToken = undefined;
  @observable currentUserClassroomAssistance = undefined;
  @observable isLoggingIn = false;
  @observable isRegisteringTeacher = false;
  @observable error = undefined;
  @observable infoMessage = undefined;
  @observable currentUserAllClassRooms = observable.map({});
  pingData = {};
  offTopicForUserLogin = undefined;

  @computed get isTeacher() {
    return !!(this.currentUser && this.currentUser.role === 'Teacher');
  }

  @computed get isStudent() {
    return !!(this.currentUser && this.currentUser.role === 'Student');
  }

  @computed get isAdmin() {
    return !!(this.currentUser && this.currentUser.role === 'Admin');
  }

  @computed get isAuth() {
    return !!(this.currentUser && this.currentUser.role);
  }

  // eslint-disable-next-line class-methods-use-this
  @computed get environmentId() {
    return webStorageStore.getShareStorageItem(ENVIRONMENT_ID_KEY);
  }

  addPingAction(actionName, _args, _cb) {
    let cb = _cb || (() => {});
    let args = _args;
    if (typeof args === 'function') {
      cb = args;
      args = undefined;
    }
    this.pingData.actions = this.pingData.actions || {};
    Object.assign(this.pingData.actions, {
      [actionName]: {
        args,
        callback: cb,
      },
    });
  }
  removePingAction(actionName) {
    this.pingData.actions = this.pingData.actions || {};
    delete this.pingData.actions[actionName];
  }
  ping() {
    const { pingData } = this;
    pingData.actions = pingData.actions || {};
    ping(pingData)
      .then((resp) => {
        if (resp && resp.data) {
          // ping actions
          const { actions } = resp.data;
          if (actions && Object.keys(actions).length > 0) {
            Object.keys(actions).forEach((key) => {
              const pingDataAction = pingData.actions[key];
              if (pingDataAction) {
                const pingRespDataAction = actions[key];
                if (pingRespDataAction) {
                  pingDataAction.callback(pingRespDataAction);
                }
              }
            });
          }
        }
      })
      .catch((error) => {
        console.reportError(error, 'ping error');
      })
      .finally(() => {
        setTimeout(this.ping.bind(this), 5 * 1e3);
      });
  }

  // eslint-disable-next-line class-methods-use-this
  @action generateEnvironmentId() {
    webStorageStore.setShareStorageItem(ENVIRONMENT_ID_KEY, `${Date.now()}-${Math.random()}`);
  }

  // eslint-disable-next-line class-methods-use-this
  @action cleanEnvironmentId() {
    webStorageStore.removeShareStorageItem(ENVIRONMENT_ID_KEY);
  }

  /**
   * Load profile from LocalStorage and update store and JWT if found any.
   */
  @action loadProfileFromLocalStorage() {
    const umidInSession = webStorageStore.getSessionItem(UMID_IN_SESSION_KEY);
    const allUMKeys = Object.keys(this.getUserManagement() || {});
    let umid = 1; // default um user is 1
    if (allUMKeys.length > 0) {
      umid = Math.max(...allUMKeys);
    }
    if (umidInSession) {
      umid = parseInt(umidInSession, 10) || umid;
    } else {
      const umidInUrl = new URLSearchParams(window.location.search).get('um');
      if (umidInUrl) {
        umid = parseInt(umidInUrl, 10) || umid;
      }
    }
    this.setUMIDToSessionStorage(umid);
    const userInfo = this.getUserManagement(umid) || {};
    const { user } = userInfo;

    // else {
    //   const userEncoded = webStorageStore.getShareStorageItem(CURRENT_USER_KEY);
    //   user = userEncoded ? JSON.parse(userEncoded) : null;
    // }
    if (user) {
      const token = webStorageStore.getItemWithNS('jwt', `${user._id}##`);
      if ('string' === typeof token && token.length > 100) {
        this.setCurrentUser(Object.assign({}, userInfo));
      }
    }
  }

  /**
   * Perform login request with data provided and if resolved, update current user in store.
   *
   * @param {!Object} data - Data with credentials to make request.
   * @returns {IPromiseBasedObservable<T>} - An fromPromise object.
   */
  @action async login(data) {
    // this.logout(); // TODO: need to re-consider this logout function with multiple user login
    const { username } = data; // username = email
    let resp;
    try {
      resp = await authenticate(data);
    } catch (error) {
      resp = error.response;
    }
    const { data: responseData } = resp;
    if (_.get(responseData, 'security.verifiedAt')) {
      let currentUMID = 0;
      const alreadyLoginInfo = this.getUserByEmailFromManagement(username);
      if (alreadyLoginInfo) {
        const [alreadyUMID] = alreadyLoginInfo;
        currentUMID = this.setUserToManagement(alreadyUMID, responseData);
      } else {
        currentUMID = this.pushUserToManagement(responseData);
      }
      this.setUMIDToSessionStorage(currentUMID);
      this.setCurrentUser(responseData);
    }
    return responseData;
  }

  @action loginWithGoogle(data) {
    return authenticateWithGoogle(data).then((resp) => {
      const { data: responseData } = resp;
      let currentUMID = 0;
      const username = _.get(responseData, 'user.username');
      const alreadyLoginInfo = this.getUserByEmailFromManagement(username);
      if (alreadyLoginInfo) {
        const [alreadyUMID] = alreadyLoginInfo;
        currentUMID = this.setUserToManagement(alreadyUMID, responseData);
      } else {
        currentUMID = this.pushUserToManagement(responseData);
      }
      this.setUMIDToSessionStorage(currentUMID);
      this.setCurrentUser(responseData);
      return responseData;
    });
  }

  @action loginWithAssistanceCode(data) {
    return authenticateWithAssistanceCode(data).then((resp) => {
      const { data: responseData } = resp;
      const { user } = responseData;
      let currentUMID = 0;
      const alreadyLoginInfo = this.getUserByEmailFromManagement(user.username);
      if (alreadyLoginInfo) {
        const [alreadyUMID] = alreadyLoginInfo;
        currentUMID = this.setUserToManagement(alreadyUMID, responseData);
      } else {
        currentUMID = this.pushUserToManagement(responseData);
      }
      this.setUMIDToSessionStorage(currentUMID);
      this.setCurrentUser(responseData);
      return responseData;
    });
  }

  /**
   * Remove currentUser and token from store and from localStorage.
   *
   * @param history
   */
  @action logout() {
    removeToken();
    webStorageStore.removeItem('jwt');
    // webStorageStore.removeShareStorageItem(CURRENT_USER_KEY);
    webStorageStore.removeSessionItem(UMID_IN_SESSION_KEY);
    if (this.currentUser) {
      this.removeUMIDByEmail(this.currentUser.email);
    }
    this.all = [];
    this.isVerifying = false;
    this.isUserVerified = false;
    this.currentUser = undefined;
    this.currentUser = undefined;
    this.currentUserAccessToken = undefined;
    this.currentUserSecurityData = undefined;
    this.isLoggingIn = false;
    this.isRegisteringTeacher = false;
    this.error = undefined;

    setUserInfo({});
    // LogRocket.identify(); // unset user

    classRoomStore.clean();

    this.cleanEnvironmentId();
    webStorageStore.setNameSpace('');
  }

  // eslint-disable-next-line
  getUserManagement(...rest) {
    const userManagementStr = webStorageStore.getShareStorageItem(USERS_MANAGEMENT_KEY);
    const emptyManagement = {};
    let userMan = emptyManagement;
    if (userManagementStr) {
      try {
        userMan = JSON.parse(userManagementStr) || emptyManagement;
      } catch (e) {
        userMan = emptyManagement;
      }
    }
    if (rest.length > 0) return userMan[`${rest[0]}`];
    return userMan;
  }

  getUserByEmailFromManagement(email) {
    return Object.entries(this.getUserManagement())
    // eslint-disable-next-line no-unused-vars
      .find(([umid, u]) => `${_.get(u, 'user.email')}`.toLowerCase() === `${email}`.toLowerCase());
  }

  pushUserToManagement(userData) {
    const um = this.getUserManagement();
    const maxUMID = Math.max(0, ...Object.keys(um));
    const currentUMID = maxUMID + 1;
    um[currentUMID] = userData;
    webStorageStore.setShareStorageItem(USERS_MANAGEMENT_KEY, JSON.stringify(um));
    return currentUMID;
  }

  setUserToManagement(muid, userData) {
    const um = this.getUserManagement();
    um[muid] = userData;
    webStorageStore.setShareStorageItem(USERS_MANAGEMENT_KEY, JSON.stringify(um));
    return muid;
  }

  // eslint-disable-next-line class-methods-use-this
  setUMIDToSessionStorage(umid) {
    return webStorageStore.setSessionItem(UMID_IN_SESSION_KEY, umid);
  }

  getUMIDByEmail(email) {
    let umid = null;
    Object.entries(this.getUserManagement()).find(([_umid, it]) => {
      if (it && `${_.get(it, 'user.email')}`.toLowerCase() === `${email}`.toLowerCase()) {
        umid = _umid;
        return true;
      }
      return false;
    });
    return umid;
  }

  removeUMIDByEmail(email) {
    const um = this.getUserManagement();
    const umid = this.getUMIDByEmail(email);
    delete um[umid];
    webStorageStore.setShareStorageItem(USERS_MANAGEMENT_KEY, JSON.stringify(um));
  }

  notifyStartUserSession() {
    if (this.currentUser) {
      this.addPingAction('startUserSession', {
        accountId: this.currentUser._id,
      }, () => {
        this.removePingAction('startUserSession');
      });
    }
  }

  notifyEndUserSession() {
    if (this.currentUser) {
      // Use sendBeacon to end user sessions so that it executes correctly on page unload
      const { pingData } = this;
      pingData.actions = { endUserSession: { args: { accountId: this.currentUser._id } } };
      navigator.sendBeacon(`${process.env.API_URL}/account/ping`, JSON.stringify(pingData));
      this.removePingAction('endUserSession');
    }
  }

  /**
   * Create new teacher with data provided.
   *
   * @param {!Object} data - Teacher data to pass to request.
   * @returns {IPromiseBasedObservable<T>} - An fromPromise object.
   */
  @action registerTeacher(data) {
    return new Promise((resolve) => {
      createTeacher(data)
        .then((_res) => {
          resolve(_.get(_res, 'data'));
        })
        .catch((error) => {
          const resData = _.get(error, 'response.data') || {};
          resolve(resData);
          this.setInfoMessage(_t(error.message));
        });
    });
  }

  /**
   * Create new student with data provided and set current user with result.
   *
   * @param {!Object} data - An student data to pass to the request.
   * @returns {IPromiseBasedObservable<T>} - An fromPromise object
   */
  @action registerStudent(data) {
    return fromPromise(createStudent(data).then((resp) => {
      this.setCurrentUser(resp.data);
      return resp.data;
    }));
  }

  /**
   * Add user to the store.
   *
   * @param {!Object} data - User to add.
   */
  @action add(data) {
    this.all.push({ ...data });
  }

  /**
   * Make a request to verify the account.
   *
   * @param {!string} token - An token to be used.
   */
  @action verify = async (token) => {
    runInAction('starting loading for action verify', () => {
      this.isVerifying = true;
      this.setErrorMessage(undefined);
    });

    try {
      await verify(token);
      // TODO Check ret
    } catch (error) {
      this.setErrorMessage(error);
    } finally {
      runInAction('stop loading for action verify', () => {
        this.isUserVerified = true;
      });
    }
  };

  // region Setters

  /**
   * Set current user data and token.
   * @param {{token: {string}, user: {Object}} - Current JWT of authenticated user and user.
   */
  @action async setCurrentUser({
    token,
    user,
    security,
    classroomAssistance,
    __accessToken,
  }) {
    log('setCurrentUser');
    webStorageStore.setNameSpace(`${user._id}##`);
    // webStorageStore.setItemWithNS(CURRENT_USER_KEY, JSON.stringify(user), '');
    // webStorageStore.setShareStorageItem(CURRENT_USER_KEY, JSON.stringify(user));

    webStorageStore.setItem('jwt', token);

    setUserInfo(user);
    // LogRocket.identify(user._id, {
    //   name: user.name,
    //   email: user.email,
    //   ...user,
    // });

    setToken(token);

    this.currentUser = user;
    this.currentUserSecurityData = security;
    this.currentUserClassroomAssistance = classroomAssistance;
    this.currentUserAccessToken = __accessToken;

    await accountStateStoreHook.loadUserStore();
    // don't use userMediaStore anymore.
    // userMediaStore.switchUserById(user._id); // load user media
  }

  @action setErrorMessage(err) {
    if (err) {
      this.error = dehydrateError(err);
    } else {
      this.error = err;
    }
  }

  @action setInfoMessage(message) {
    this.infoMessage = message;
  }

  // endregion
}

export default new UserStore();
export { UserStore };
